import { Col, Divider, Flex, Layout, message, Row, Space, Spin, Typography, Image } from "antd"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Email, FB, Kakao, Link, Twitter } from "../../assets/svg"
import { Errors } from "../../contexts/ErrorContext"
import { MainLayout } from "../../layout/MainLayout"
import { BlogModel } from "../../model/BlogModel"
import { BlogService } from "../../service/BlogService"
import { MenuType } from "../../type/MenuType"
import DatetimeUtility from "../../util/DatetimeUtility"
import { Header } from "./Header"

export const BlogDetail = () => {
    const { id } = useParams()
    const navigate = useNavigate()

    if (!id) {
        message.error("콘텐츠를 찾을 수 없습니다.", 1, () => { navigate("/views/blog") })
    }

    const [isLoading, setLoading] = useState<boolean>(false)
    const [blog, setBlog] = useState<BlogModel>()

    const detail = async (blogId: string) => {
        setLoading(true)

        const response = await BlogService.blog(blogId)
        if (response.status === 200) {
            setBlog(response.data.item)
        } else {
            Errors.AjaxError(response.data)
        }

        setLoading(false)
    }

    useEffect(() => {
        if (id) {
            detail(id)
        }
    }, [])

    return (
        <MainLayout menuType={MenuType.Blog}>
            <Layout.Content className="blog">
                <Spin spinning={isLoading}>
                    {blog && (
                        // <>
                        //     {blog.image && (
                        //         <Header url={blog.image.largeUrl} />
                        //     )}
                        //     {/* <Position /> */}
                        //     <Row gutter={[0, 24]} justify={"center"} style={{ marginTop: 88, marginBottom: 88, }}>
                        //         <Col span={24}>
                        //             <Flex justify="center">
                        //                 <Space size={12} split={"|"} style={{ color: '#d6d7da', fontSize: 12 }}>
                        //                     <Typography.Text style={{ color: '#F8B31A' }}>{blog.blogTypeDisplayName}</Typography.Text>
                        //                     <Typography.Text type="secondary">
                        //                         {DatetimeUtility.toKorean(blog.created, 'YYYY년 M월 D일')}
                        //                     </Typography.Text>
                        //                 </Space>
                        //             </Flex>
                        //         </Col>
                        //         <Col span={24}>
                        //             <Flex justify="center">
                        //                 <Typography.Title level={1} style={{ fontWeight: 700, }}>{blog.title}</Typography.Title>
                        //             </Flex>
                        //         </Col>
                        //         <Col span={24}>
                        //             <Flex justify="center">
                        //                 <Typography.Title level={5} style={{ fontWeight: 500, }}>{blog.subtitle}</Typography.Title>
                        //             </Flex>
                        //         </Col>
                        //         <Col span={24}>
                        //             <Divider />
                        //         </Col>
                        //         {/* <Col span={24}>
                        //             <Row gutter={[0, 24]} justify={"center"} style={{ marginTop: 88, marginBottom: 88, }}>
                        //                 <Col span={24} style={{ maxWidth: 980, borderTop: '1px solid #d6d7da' }}>
                        //                     <Row gutter={[16, 16]}>
                        //                         <Col span={24}>
                        //                             <Flex justify="center">
                        //                                 <Space wrap size={6}>
                        //                                     <div>
                        //                                         <Kakao />
                        //                                     </div>
                        //                                     <div>
                        //                                         <FB />
                        //                                     </div>
                        //                                     <div>
                        //                                         <Twitter />
                        //                                     </div>
                        //                                     <div>
                        //                                         <Email />
                        //                                     </div>
                        //                                     <div>
                        //                                         <Link />
                        //                                     </div>
                        //                                 </Space>
                        //                             </Flex>
                        //                         </Col>
                        //                     </Row>
                        //                 </Col>
                        //             </Row>
                        //         </Col> */}
                        //         <Col span={24}>
                        //             <Flex justify="center">
                        //                 <div dangerouslySetInnerHTML={{ __html: blog.content }} />
                        //             </Flex>
                        //         </Col>
                        //     </Row>
                        // </>

                        <Row gutter={[0, 24]}>
                            {blog.image && (
                                <Col span={24}>
                                    <Image
                                        preview={false}
                                        src={blog.image.largeUrl}
                                        width={'100%'}
                                        height={600}
                                        style={{
                                            width: '100%',
                                            objectFit: 'cover'
                                        }}
                                    />
                                </Col>
                            )}
                            <Col span={24}>
                                <Flex justify="center" style={{ marginTop: 88, marginBottom: 88, }}>
                                    <Space size={12} split={"|"} style={{ color: '#d6d7da', fontSize: 12 }}>
                                        <Typography.Text style={{ color: '#F8B31A' }}>{blog.blogType}</Typography.Text>
                                        <Typography.Text type="secondary">
                                            {DatetimeUtility.toKorean('2024-09-08 00:00:00', 'YYYY년 M월 D일')}
                                        </Typography.Text>
                                    </Space>
                                </Flex>
                            </Col>
                            <Col span={24}>
                                <Row gutter={[0, 24]} style={{ maxWidth: 780, margin: '0 auto' }}>
                                    <Col span={24}>
                                        <Flex justify="center">
                                            <Typography.Title level={2} style={{ fontWeight: 700, }}>{blog.title}</Typography.Title>
                                        </Flex>
                                    </Col>
                                    <Col span={24}>
                                        <Flex justify="center">
                                            <Typography.Title level={5} style={{ fontWeight: 500, }}>{blog.subtitle}</Typography.Title>
                                        </Flex>
                                    </Col>
                                    <Col span={24} style={{ marginTop: 42, }}>
                                        <Divider style={{ borderBlockStart: '1px solid #d6d7da' }} />
                                    </Col>
                                    <Col span={24} style={{ marginTop: 22, }}>
                                        <div className="ql-editor">
                                            <div dangerouslySetInnerHTML={{ __html: blog?.content ?? '' }}></div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    )}
                </Spin>
            </Layout.Content>
        </MainLayout>
    )
}