import { Col, Empty, Flex, Image, Layout, Pagination, Row, Space, Spin, Typography } from "antd"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { EmptyIcon } from "../../assets/svg"
import { Errors } from "../../contexts/ErrorContext"
import { MainLayout } from "../../layout/MainLayout"
import { BlogModel } from "../../model/BlogModel"
import { PaginationModel } from "../../model/PaginationModel"
import { BlogService } from "../../service/BlogService"
import { BlogType } from "../../type/BlogType"
import { MenuType } from "../../type/MenuType"
import { Header } from "./Header"

export const Blog = () => {
    const navigate = useNavigate()

    const [isLoading, setLoading] = useState<boolean>(true)
    const [page, setPage] = useState<PaginationModel<BlogModel>>()
    const [blogType, setBlogType] = useState<BlogType | null>(null)

    const menus = [
        { name: '전체', },
        { name: '브랜딩', type: BlogType.BRANDING },
        { name: '퍼포먼스', type: BlogType.PERFORMANCE },
        { name: '콘텐츠', type: BlogType.CONTENT },
        { name: '애드테크', type: BlogType.TECH },
    ]

    const search = async (page: number = 1) => {
        setLoading(true)

        const response = await BlogService.search({
            page: page,
            blogType: blogType,
        })

        if (response.status === 200) {
            setPage(response.data.item)
        } else {
            Errors.AjaxError(response.data)
        }

        setLoading(false)
    }

    useEffect(() => {
        search()
    }, [blogType])

    return (
        <MainLayout menuType={MenuType.Blog}>
            <Layout.Content className="blog">
                <Header />
                <Row gutter={[0, 24]} justify={"center"} style={{ marginTop: 88, marginBottom: 88, }}>
                    <Col span={24}>
                        <Flex justify="center">
                            <Typography.Title level={1} style={{ fontWeight: 700, }}>{'함께가치'}</Typography.Title>
                        </Flex>
                    </Col>
                    <Col span={24}>
                        <Flex justify="center">
                            <Space direction="vertical" size={24}>
                                <Space direction="vertical" wrap style={{ textAlign: 'center' }} size={6}>
                                    <Typography.Text style={{ fontSize: 16, }}>{'지금까지의 성장은 결코 홀로 이뤄낸 것이 아닙니다.'}</Typography.Text>
                                    <Typography.Text style={{ fontSize: 16, }}>{'고객과 사장님, 라이더, 구성원과 함께 이뤄낸 눈부신 성과이지요.'}</Typography.Text>
                                    <Typography.Text style={{ fontSize: 16, }}>{`'함께'이기에 가능했고, '함께'이기에 가치 있었습니다.`}</Typography.Text>
                                </Space>
                                <Space direction="vertical" wrap style={{ textAlign: 'center' }} size={6}>
                                    <Typography.Text style={{ fontSize: 16, }}>{'앞으로도 #####은 외식 산업이 건강하게 성장할 수 있도록'}</Typography.Text>
                                    <Typography.Text style={{ fontSize: 16, }}>{'음식이 주는 행복에서 소외 받는 사람이 없도록'}</Typography.Text>
                                    <Typography.Text style={{ fontSize: 16, }}>{'다음 세대를 위해 환경을 보전할 수 있도록 책임을 다하겠습니다.'}</Typography.Text>
                                </Space>
                            </Space>
                        </Flex>
                    </Col>
                </Row>
                <Row gutter={[0, 24]} justify={"center"} style={{ marginTop: 88, marginBottom: 88, }}>
                    <Col span={24}>
                        <Row>
                            <Col span={24} style={{ borderBottom: '1px solid #0000001a', paddingBottom: 20, }}>
                                <Flex justify="center">
                                    <Space size={4} split={"|"} style={{ color: '#d6d7da', fontSize: 20 }}>
                                        {menus.map((m) => {
                                            const isActivated = (m.type == null && blogType == null) || blogType === m.type

                                            return (
                                                <Typography.Text
                                                    key={m.type}
                                                    className="menu"
                                                    style={{
                                                        color: isActivated ? '#232324' : '#d6d7da',
                                                    }}
                                                    onClick={() => {
                                                        setBlogType(m.type ?? null)
                                                    }}
                                                >
                                                    {m.name}
                                                </Typography.Text>
                                            )
                                        })}
                                    </Space>
                                </Flex>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24} style={{ maxWidth: 980, }}>
                        <Spin spinning={isLoading}>
                            <Row gutter={[16, 16]}>
                                {page?.items?.map((r) => {
                                    return (
                                        <Col
                                            className="items"
                                            key={r.title}
                                            {...{ xs: 24, sm: 12 }}
                                            style={{
                                                minHeight: 480,
                                            }}
                                            onClick={() => {
                                                navigate(`/views/blog/${r.id}`)
                                            }}
                                        >
                                            {r.image && (
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        minHeight: 345,
                                                        overflow: 'hidden',
                                                        borderTopRightRadius: 6,
                                                        borderTopLeftRadius: 6,
                                                    }}
                                                >
                                                    <Image
                                                        preview={false}
                                                        src={r.image.middleUrl}
                                                        style={{
                                                            height: '100%',
                                                            minHeight: 345,
                                                            objectFit: 'cover'
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            <Space
                                                direction="vertical"
                                                style={{
                                                    marginTop: 12,
                                                }}
                                                wrap
                                            >
                                                <Typography.Text
                                                    style={{
                                                        fontSize: 20,
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    {r.title}
                                                </Typography.Text>
                                                <Typography.Paragraph
                                                    style={{ marginBottom: 0, }}
                                                    ellipsis={{
                                                        rows: 2,
                                                    }}
                                                >
                                                    <Typography.Text
                                                        style={{
                                                            fontSize: 16,
                                                        }}
                                                    >
                                                        {r.subtitle}
                                                    </Typography.Text>
                                                </Typography.Paragraph>
                                                <Typography.Text
                                                    style={{
                                                        fontSize: 12,
                                                        color: '#F8B31A'
                                                    }}
                                                >
                                                    {r.blogTypeDisplayName}
                                                </Typography.Text>
                                            </Space>
                                        </Col>
                                    )
                                })}

                                {(page?.items ?? []).length <= 0 && (
                                    <Col span={24} style={{ marginTop: '5em', marginBottom: '5em' }}>
                                        <Empty
                                            image={<EmptyIcon />}
                                            description={
                                                <Space direction="vertical">
                                                    <Typography.Text type="secondary">
                                                        {'추가 콘텐츠를 제작중에 있어요!'}
                                                    </Typography.Text>
                                                    <Typography.Text type="secondary">
                                                        {'조금만 기다려주세요 :)'}
                                                    </Typography.Text>
                                                </Space>
                                            }
                                        >
                                        </Empty>
                                    </Col>
                                )}
                            </Row>
                        </Spin>
                    </Col>
                    <Col span={24} style={{ maxWidth: 980, marginTop: 24, }}>
                        <Flex justify="center">
                            <Pagination
                                defaultCurrent={1}
                                current={page?.currentPageIndex}
                                total={page?.totalItemCount}
                                pageSize={page?.size}
                                onChange={(page: number) => {
                                    search(page)
                                }}
                            />
                        </Flex>
                    </Col>
                </Row>
            </Layout.Content>
        </MainLayout>
    )
}