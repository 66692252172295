import { CloseOutlined, MenuOutlined } from '@ant-design/icons'
import { Col, Flex, Image, Layout, Row, Space } from "antd"
import { FunctionComponent, useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Logo, WhiteLogo } from '../assets/images'
import { MenuType } from "../type/MenuType"

interface IProps {
    menuType?: MenuType
}

export const Navigation: FunctionComponent<IProps> = (props) => {
    const { menuType } = props

    const [show, setShow] = useState<boolean>(false)
    const [open, setOpen] = useState<boolean>(false)

    const navigate = useNavigate()
    const menus = [
        { type: MenuType.Home, label: 'Home', path: '/' },
        // { type: MenuType.Blog, label: 'Blog', path: '/views/blog' },
        { type: MenuType.WhoWeAre, label: 'Who we are', path: '/views/who-we-are' },
        { type: MenuType.Works, label: 'Works', path: '/views/works' },
        { type: MenuType.Wanted, label: 'Wanted', path: '/views/wanted' },
        { type: MenuType.Contact, label: 'Contact', path: '/views/contact' },
    ]

    useEffect(() => {
        window.addEventListener("scroll", handleNavbar)
        return () => {
            window.removeEventListener("scroll", handleNavbar)
        }
    }, [])

    const handleNavbar = () => {
        window.scrollY > 50 ? setShow(true) : setShow(false)
    }

    return (
        <Layout.Header className={`${show ? "ant-layout-sticky-header" : ""}`}>
            <Layout.Content>
                <Row className="top" justify={"space-between"} align={"middle"} style={{ height: '100%' }}>
                    <Col
                        {...{ xs: 14, md: 4, lg: 4, }}
                        onClick={() => {
                            navigate("/")
                        }}
                    >
                        <Image
                            src={show ? Logo : WhiteLogo}
                            preview={false}
                            height={35}
                            style={{
                                objectFit: 'contain'
                            }}
                        />
                    </Col>
                    <Col {...{ xs: 8, sm: 4, md: 4, lg: 0, xl: 0, xxl: 0 }}>
                        <Flex justify="end">
                            <MenuOutlined
                                onClick={() => {
                                    setOpen(!open)
                                }}
                                style={{
                                    color: show ? '#000' : '#fff',
                                    fontSize: 24,
                                }}
                            />
                        </Flex>
                    </Col>
                    <Col {...{ xs: 0, sm: 0, md: 0, lg: 20 }}>
                        <Flex justify="end" align={"middle"} className="navigation">
                            <Space wrap size={34}>
                                {menus.map((m) => {
                                    const isActivate = m.type === menuType
                                    return (
                                        <Link
                                            key={m.type}
                                            to={m.path}
                                            className={isActivate ? 'activate' : ''}
                                        >
                                            <h2>{m.label}</h2>
                                        </Link>
                                    )
                                })}
                            </Space>
                        </Flex>
                    </Col>
                </Row>
                <Row>
                    <Col {...{ xs: 24, sm: 24, md: 24, lg: 0, xl: 0, xxl: 0 }}>
                        <div
                            style={{
                                position: 'absolute',
                                top: -75,
                                left: open ? (window.innerWidth > 576 ? -55 : 0) : '100%',
                                right: 0,
                                bottom: 0,
                                zIndex: 120,
                                backgroundColor: '#fff',
                                height: window.innerHeight,
                                width: window.innerWidth,
                                opacity: open ? 1 : 0
                            }}
                        >
                            <Flex justify="end">
                                <CloseOutlined
                                    onClick={() => {
                                        setOpen(!open)
                                    }}
                                    style={{ fontSize: 24, padding: 16, }}
                                />
                            </Flex>
                            <Flex justify="center" className="navigation">
                                <Space wrap size={0} direction="vertical">
                                    {menus.map((m) => {
                                        const isActivate = m.type === menuType

                                        return (
                                            <Link
                                                key={m.type}
                                                to={m.path}
                                                className={`${isActivate ? 'activate' : ''}`}
                                                style={{ textAlign: 'center' }}
                                            >
                                                <h2>{m.label}</h2>
                                            </Link>
                                        )
                                    })}
                                </Space>
                            </Flex>
                        </div>
                    </Col>
                </Row>
            </Layout.Content>
        </Layout.Header>
    )
}