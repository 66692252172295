export const Path = {
    home: "/",
    who_we_are: "/views/who-we-are",
    works: "/views/works",
    wanted: "/views/wanted",
    contact: "/views/contact",
    blog: "/views/blog",
    blog_detail: "/views/blog/:id",
    login: "/views/login",
    privacy_term: "/views/privacy-term",
}