import { Col, Row } from "antd"
import { FunctionComponent } from "react"

interface IProps {
    url?: string
}

export const Header: FunctionComponent<IProps> = (props) => {
    const { url } = props

    const height = window.innerHeight
    const width = window.innerWidth

    return (
        <Row className="header" style={{ position: 'relative' }}>
            <Col
                span={24}
                className="parallax"
                style={{
                    height: width > 576 ? height / 2 : height,
                    backgroundPosition: width > 576 ? 'center' : 'right',
                    backgroundImage: url ? `url('${url}')` : "url('https://woowahan-cdn.woowahan.com/static/image/together/pc/visual@3x.jpg?59bd60cb')"
                }}
            />
            <div 
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.4)'
                }}
            />
        </Row>
    )
}